<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">แก้ไขTicket</h1>
    </v-col>

    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน" disabled></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อTicket</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ form.name }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">เรื่อง</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ form.subject }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">คำถาม</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ form.question }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">คำตอบ</span>
          </v-col>
          <v-col cols="12" md="7" sm="7">
            <span>{{ form.answer }}</span>
          </v-col>
        </v-row>
        <!-- <v-row align="center" justify="center">
          <v-col cols="12" md="9" sm="9" xs="9">
            <v-card outlined>
              <v-row no-gutters align="center">
                <v-col
                  cols="10"
                  md="10"
                  sm="10"
                  xs="10"
                  class="ma-4"
                  id="step-3"
                >
                  <span>รูปแบรนด์สินค้า</span>

                  <v-img :src="showImage" width="200px"></v-img> -->
        <!-- <v-text-field
                    v-model="picture_1"
                    @click="changePic1()"
                    :placeholder="imageName1"
                    prepend-icon="mdi-camera"
                    readonly
                    :rules="rules.pic"
                  >
                  </v-text-field
                  ><input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/png"
                    @change="showPicture1($event)"
                    style="display: none"
                /> -->
        <!-- </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="#234baa" dark>บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      form: {
        name: "",
        subject: "",
        question: "",
        answer: "",
      },
      id: "",
      categoryname: "",
      categoryDescription: "",
      activeFlag: false,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    var Catedata = JSON.parse(Decode.decode(localStorage.getItem("Catedata")));
    console.log("Catedata", Catedata);
    this.id = Catedata.id;
    this.categoryname = Catedata.category;
    this.categoryDescription = Catedata.description;
    this.activeFlag = Catedata.activeFlag;
    this.showImage = Catedata.categoryImg;
    this.pic1 = Catedata.categoryImg;
    this.picture_1 = Catedata.categoryImg;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageTicket");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(Decode.decode(localStorage.getItem("userSPM")));
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/categories/` + this.id,
          this.form,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างTicketสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCategory");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างTicketสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
